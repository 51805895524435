import React from "react";
import modules from "./LeftSideBar.module.scss";
import { Col, Container, Nav, Row } from "react-bootstrap";
import imageMain from "../../assets/picImageMain.png";
import Typewriter from "typewriter-effect";
import { useHistory } from "react-router-dom";
import emailIcon from "../../assets/email.png";
import instagramIcon from "../../assets/instagram.png";
import githubIcon from "../../assets/github.png";
import linkedinIcon from "../../assets/linkedin.png";
import locationIcon from "../../assets/location.png";
const LeftSideBar = () => {
  const history = useHistory();

  function directUrl(url) {
    console.log(url);
    history.push(url);
  }

  return (
    <>
      <div
        className={modules.typeWriter__phone}
        style={{ paddingTop: 10, backgroundColor: "#373b3e" }}
      >
        <Row sm={2} lg={2} xs={1} md={2}>
          {/* <Col>
            <Typewriter
              options={{
                skipAddStyles: true,
                strings: ["May the Force be with you..."],
                autoStart: true,
                loop: true,
                delay: 50,
              }}
            />
          </Col> */}
          <Col>
            <a
              className={modules.contact__phone}
              href={"mailto:erenkorkmazemre@gmail.com"}
              target="_blank"
            >
              <img
                src={emailIcon}
                style={{ marginLeft: 10, transition: "all" }}
              />
            </a>
            <a
              className={modules.contact__phone}
              href={"https://goo.gl/maps/8jfvVQA93dPEARk4A"}
              target="_blank"
            >
              <img
                src={locationIcon}
                style={{ marginLeft: 10, transition: "all" }}
              />
            </a>
            <a
              className={modules.contact__phone}
              href={"https://www.linkedin.com/in/erenemrekorkmaz/"}
              target="_blank"
            >
              <img
                src={linkedinIcon}
                style={{ marginLeft: 10, transition: "all" }}
              />
            </a>
            <a
              className={modules.contact__phone}
              href={"https://www.instagram.com/errenkorkmaz/"}
              target="_blank"
            >
              <img
                src={instagramIcon}
                style={{ marginLeft: 10, transition: "all" }}
              />
            </a>
            <a
              className={modules.contact__phone}
              href={"https://github.com/erenkorkmazemre"}
              target="_blank"
            >
              <img
                src={githubIcon}
                style={{ marginLeft: 10, transition: "all" }}
              />
            </a>
          </Col>
        </Row>
      </div>
      <div style={{ backgroundColor: "#373b3e" }}>
        <ul className={modules.leftNavigation__leftNavigation__inline}>
          <li
            className={modules.leftNavigation__leftNavigation}
            onClick={() => {
              directUrl("/home");
            }}
          >
            Home
          </li>
          <li
            className={modules.leftNavigation__leftNavigation}
            onClick={() => {
              directUrl("/experience");
            }}
          >
            Experience
          </li>
          <li
            className={modules.leftNavigation__leftNavigation}
            onClick={() => {
              directUrl("/blog");
            }}
          >
            Blog
          </li>
        </ul>
      </div>

      <div className={modules.body__body}>
        <Container
          className={modules.background__background}
          style={{ width: 190, height: 190, textAlign: "center" }}
        >
          <img
            className={modules.backgroundImage__backgroundImage}
            src={imageMain}
            alt="imageMain"
          />
          <div style={{ marginTop: "5px" }}>Eren Emre Korkmaz</div>
        </Container>
        {/* <div className={modules.typeWriter}>
          <Typewriter
            options={{
              skipAddStyles: true,
              strings: ["May the Force be with you..."],
              autoStart: true,
              loop: true,
              delay: 50,
            }}
          />
        </div> */}
        <div>
          <div style={{ marginTop: "40px" }}>
            <ul className="App-header">
              <li
                className={modules.leftNavigation__leftNavigation}
                onClick={() => {
                  directUrl("/home");
                }}
              >
                Home
              </li>
              <li
                className={modules.leftNavigation__leftNavigation}
                onClick={() => {
                  directUrl("/experience");
                }}
              >
                Experience
              </li>
              <li
                className={modules.leftNavigation__leftNavigation}
                onClick={() => {
                  directUrl("/blog");
                }}
              >
                Blog
              </li>
            </ul>
            <ul className="App-header"></ul>
          </div>
          <div style={{ display: "table-caption" }}>
            <Row>
              <Col>
                <a
                  className={modules.contact}
                  href={"mailto:erenkorkmazemre@gmail.com"}
                  target="_blank"
                >
                  <img
                    src={emailIcon}
                    style={{ marginLeft: 10, transition: "all" }}
                  />
                </a>
              </Col>
              <Col>
                <a
                  className={modules.contact}
                  href={"https://goo.gl/maps/8jfvVQA93dPEARk4A"}
                  target="_blank"
                >
                  <img
                    src={locationIcon}
                    style={{ marginLeft: 10, transition: "all" }}
                  />
                </a>
              </Col>
              <Col>
                <a
                  className={modules.contact}
                  href={"https://www.linkedin.com/in/erenemrekorkmaz/"}
                  target="_blank"
                >
                  <img
                    src={linkedinIcon}
                    style={{ marginLeft: 10, transition: "all" }}
                  />
                </a>
              </Col>
              <Col>
                <a
                  className={modules.contact}
                  href={"https://github.com/erenkorkmazemre"}
                  target="_blank"
                >
                  <img
                    src={githubIcon}
                    style={{ marginLeft: 10, transition: "all" }}
                  />
                </a>
              </Col>
              <Col>
                <a
                  className={modules.contact}
                  href={"https://www.instagram.com/errenkorkmaz/"}
                  target="_blank"
                >
                  <img
                    src={instagramIcon}
                    style={{ marginLeft: 10, transition: "all" }}
                  />
                </a>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftSideBar;
