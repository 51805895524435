import React, { useEffect } from "react";
import ReactGA from "react-ga";
import LeftSideBar from "../../components/leftSidebar";
import { Col, Container, Row } from "react-bootstrap";
import MainPage from "../mainPage";
import BottomSideBar from "../../components/bottomSideBar";
import BlogPage from "../blogPage/BlogPage";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom"; //TODO buildlemeden once HashRouter yap burayi
import { ProtectedRoute } from "../../routes/ProtectedRoute";
import Experience from "../experience/Experience";

const TRACKING_ID = "UA-193959834-1";

ReactGA.initialize(TRACKING_ID);

const LandingPage = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <Container fluid={true} style={{ height: "100%",paddingLeft:0,paddingRight:0 }}>
      <Router>
        <Switch>
          <Route exact path="/" component={MainPage} />
          <Route exact path="/experience" component={Experience} />
          <Route exact path="/blog" component={BlogPage} />
          <Route path="/">
            <Redirect to="/" component={MainPage} />
          </Route>
        </Switch>
      </Router>
    </Container>
  );
};

export default LandingPage;
