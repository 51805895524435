import React from "react";
import PropTypes from "prop-types";
import modules from "./StickTime.module.scss";
import { Col, Container, Row } from "react-bootstrap";

function StickTime({ title, time, experience }) {
  return (
    <div className={modules.timeline}>
      <div className={modules.outer}>
        <div className={modules.card}>
          <div className={modules.info}>
            <h3 className={modules.title}>Getir</h3>
            <h5 className={modules.betweenTime}>Software Quality Assurance Test Engineer II</h5>
            <h6 className={modules.betweenTime}>Dec 2022 - Present</h6>
            <p>
              Market, Finance, Franchise Domain. Coded and analyzed the UI, and
              API tests with Selenium and Rest Assured. Coded and analyzed the
              UI, and API tests with Playwright and Jest. Using test rail to
              create and run test cases Create and maintain functionality, e2e,
              regression, and smoke test sets. Working on automating test cases
              and reporting results. Working with multi-cultural and
              cross-functional teams. Test Data Management.
            </p>
          </div>
        </div>
        <div className={modules.card}>
          <div className={modules.info}>
            <h3 className={modules.title}>Turkcell</h3>
            <h5 className={modules.betweenTime}>Test Automation Engineer</h5>
            <h6 className={modules.betweenTime}>
              November 2021 - November 2022
            </h6>
            <p>
              Coded and analyzed the UI tests of two city hospitals. Serenity,
              Selenium, and Jenkins are mainly in the project. Working on
              automating test cases and reporting results.
            </p>
          </div>
        </div>
        <div className={modules.card}>
          <div className={modules.info}>
            <h3 className={modules.title}>UESTCO Energy Systems</h3>
            <h5 className={modules.betweenTime}>Frontend Developer</h5>
            <h6 className={modules.betweenTime}>
              March 2021 - November 2021
            </h6>
            <p>
              Provided coding and deploying on the warehouse management system.
              Mainly PostgreSQL, React JS and Spring Boot were used.
            </p>
          </div>
        </div>
        <div className={modules.card}>
          <div className={modules.info}>
            <h3 className={modules.title}>Erik Technology Labs</h3>
            <h5 className={modules.betweenTime}>Backend Developer</h5>
            <h6 className={modules.betweenTime}>August 2020 - March 2021</h6>
            <p>
              DevOps experience with Docker and deploying in the Cloud, CI/CD
              processes Mainly MongoDB, React JS and Spring Boot were used.
              Microservices Architectures used. Work with Java, Spring Boot, and
              databases like MongoDB and PostgreSQL for API development and
              management
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

StickTime.propTypes = {};

export default StickTime;
