import React from "react";
import { Container } from "react-bootstrap";
import { Col, Nav, Row } from "react-bootstrap";
import modules from "./BlogPage.module.scss";
import LeftSideBar from "../../components/leftSidebar/LeftSideBar";
import StickTime from "../../components/stickTime/StickTime";

const BlogPage = () => {
  return (
    <>
      <LeftSideBar />
      <Container className={modules.body__body}>
        <Row style={{ marginTop: 20 }}>
          <h3>Blog</h3>
          <Container style={{ marginTop: 20 }}>
            <Row sm={2} lg={3} md={4} xs={1}>
              <Col className={modules.cards__cards}>
                <div>Backend 🍃</div>
                <br />
                • Microservice Architecture
                <br />
                • MVC
                <br />• Spring Boot
                <Nav
                  activeKey="/be"
                  onSelect={(selectedKey) => alert(`SOON ${selectedKey}`)}
                >
                  <Nav.Item>
                    <Nav.Link href="/be">
                      <div style={{ color: "#373b3e" }}>🚀 Blog</div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col className={modules.cards__cards}>
                <div>Frontend 💥</div>
                <br />
                • React JS
                <br />
                • Scalable and modular code base
                <br />• Hooks
                <Nav
                  activeKey="/fe"
                  onSelect={(selectedKey) => alert(`SOON ${selectedKey}`)}
                >
                  <Nav.Item>
                    <Nav.Link href="/fe">
                      <div style={{ color: "#373b3e" }}>🚀 Blog</div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col className={modules.cards__cards}>
                <div>QA Software 🐜</div>
                <br />
                • Rest Assured
                <br />
                • Selenium
                <br />• Playwright
                <br />• Jest
                <br />• TestNG
                <Nav
                  activeKey="/qa"
                  onSelect={(selectedKey) => alert(`SOON ${selectedKey}`)}
                >
                  <Nav.Item>
                    <Nav.Link href="/qa">
                      <div style={{ color: "#373b3e" }}>🚀 Blog</div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col className={modules.cards__cards}>
                <div>CI/CD ☁️</div>
                <br />
                • Jenkins
                <br />
                • Bitbucket
                <br />• Gitlab
                <Nav
                  activeKey="/ci-cd"
                  onSelect={(selectedKey) => alert(`SOON ${selectedKey}`)}
                >
                  <Nav.Item>
                    <Nav.Link href="/ci-cd">
                      <div style={{ color: "#373b3e" }}>🚀 Blog</div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
    </>
  );
};

export default BlogPage;
