import React from "react";
import { Container } from "react-bootstrap";
import { Col, Nav, Row } from "react-bootstrap";
import modules from "./Experience.module.scss";
import LeftSideBar from "../../components/leftSidebar/LeftSideBar";
import StickTime from "../../components/stickTime/StickTime";

const Experience = () => {
  return (
    <>
    <LeftSideBar />
    <Container className={modules.body__body}>
      <Container>
        <Row>
          <Col>
            <h3>Experience</h3>
            <StickTime />
          </Col>
          <Col>
            <Container>
              <h3>Skills</h3>
              <div className={modules.headOfTheTitles}>Core Skills</div>
              <Row className={modules.rowTextDecoration}>
                <Col className={modules.sidebarCol}>Java</Col>
                <Col className={modules.sidebarCol}>Restful Services</Col>
                <Col className={modules.sidebarCol}>MVC</Col>
                <Col className={modules.sidebarCol}>Mongo DB</Col>
                <Col className={modules.sidebarCol}>JavaScript</Col>
                <Col className={modules.sidebarCol}>
                  Microservice Architecture
                </Col>
                <Col className={modules.sidebarCol}>ORM</Col>
                <Col className={modules.sidebarCol}>PostgreSQL</Col>
                <Col className={modules.sidebarCol}>JSON</Col>
              </Row>
            </Container>
            <Container>
              <div className={modules.headOfTheTitles}>
                Area of Expertise Frameworks & Libraries
              </div>
              <Row className={modules.rowTextDecoration}>
                <Col className={modules.sidebarCol}>Selenium Grid</Col>
                <Col className={modules.sidebarCol}>Spring Boot</Col>
                <Col className={modules.sidebarCol}>Rest Assured</Col>
                <Col className={modules.sidebarCol}>React JS</Col>
                <Col className={modules.sidebarCol}>TestNG</Col>
                <Col className={modules.sidebarCol}>Cucumber</Col>
                <Col className={modules.sidebarCol}>JUnit</Col>
                <Col className={modules.sidebarCol}>Selenium</Col>
                <Col className={modules.sidebarCol}>Playwright</Col>
              </Row>
            </Container>
            <Container>
              <div className={modules.headOfTheTitles}>
                Tools and Methodologies
              </div>
              <Row className={modules.rowTextDecoration}>
                <Col className={modules.sidebarCol}>Test Rail</Col>
                <Col className={modules.sidebarCol}>Scrum</Col>
                <Col className={modules.sidebarCol}>Maven</Col>
                <Col className={modules.sidebarCol}>POSTMAN</Col>
                <Col className={modules.sidebarCol}>Confuluence</Col>
                <Col className={modules.sidebarCol}>Google Cloud</Col>
                <Col className={modules.sidebarCol}>Proxyman</Col>
                <Col className={modules.sidebarCol}>AWS</Col>
                <Col className={modules.sidebarCol}>Bitbucket</Col>
                <Col className={modules.sidebarCol}>Docker</Col>
                <Col className={modules.sidebarCol}>New Relic</Col>
                <Col className={modules.sidebarCol}>Git</Col>
                <Col className={modules.sidebarCol}>Heroku</Col>
                <Col className={modules.sidebarCol}>JIRA</Col>
                <Col className={modules.sidebarCol}>CI/CD</Col>
                <Col className={modules.sidebarCol}>BDD / TDD</Col>
                <Col className={modules.sidebarCol}>Swagger</Col>
                <Col className={modules.sidebarCol}>Gitlab</Col>
                <Col className={modules.sidebarCol}>SDLC</Col>
                <Col className={modules.sidebarCol}>STLC</Col>
                <Col className={modules.sidebarCol}>Agile</Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Container>
    </>
  );
};

export default Experience;
